import { atom } from 'jotai';

// State atoms
export const searchQueryAtom = atom('', (_get, set, newQuery: string) => {
  // Set the new value of searchQueryAtom
  set(searchQueryAtom, newQuery);

  // Update the URL with the new search query
  const url = new URL(window.location.href);
  if (newQuery) {
    // If the query exists, set the 'q' parameter
    url.searchParams.set('query', newQuery);
  } else {
    // If the search query is empty, remove the 'q' parameter
    url.searchParams.delete('query');
  }

  // Update the browser URL without reloading the page
  window.history.pushState({}, '', url.toString());
});

export const initializeSearchQueryFromUrlAtom = atom(
  null, // No initial state is needed
  (_get, set) => {
    const queryParams = new URLSearchParams(window.location.search); // Get the query parameters from the URL
    const searchQuery = queryParams.get('query') || ''; // Get the 'query' parameter from the URL

    set(searchQueryAtom, searchQuery); // Set the parsed search query into the searchQueryAtom
  }
);

export const currentPaginationPageAtom = atom(
  1, // Default to page 1
  (_get, set, newPage: number) => {
    // Set the new value of currentPaginationPageAtom
    set(currentPaginationPageAtom, newPage);

    // Update the URL with the new page number
    const url = new URL(window.location.href);
    if (newPage !== 1) {
      // If the page is not 1, set the 'page' parameter
      url.searchParams.set('page', newPage.toString());
    } else {
      // If page is 1, remove the 'page' parameter to clean up the URL
      url.searchParams.delete('page');
    }

    // Update the browser URL without reloading the page
    window.history.pushState({}, '', url.toString());
  }
);
export const initializePaginationPageFromUrlAtom = atom(
  null, // No initial state is needed
  (_get, set) => {
    const queryParams = new URLSearchParams(window.location.search); // Get the query parameters from the URL
    const page = parseInt(queryParams.get('page') || '1', 10); // Get the 'page' parameter from the URL and parse it as an integer

    set(currentPaginationPageAtom, page); // Set the parsed page number into currentPaginationPageAtom
  }
);

export const isBlogSlideOutOpenAtom = atom<boolean>(false);
export const isBlogSearchReadyAtom = atom<boolean>(false);
export const searchResultsAtom = atom<any>(null);

// Derived atom to update the URL based on searchFiltersAtom
export const searchFiltersAtom = atom(
  {
    category: null,
    tag: null,
  },
  (_get, set, newFilters: any) => {
    console.log(newFilters);
    // Set the search filters atom with new values
    set(searchFiltersAtom, newFilters);

    // Get the current URL
    const url = new URL(window.location.href);

    // Construct the query string based on the new filters
    if (newFilters.category) {
      url.searchParams.set('category', newFilters.category);
    } else {
      // If category is null, remove it from the URL
      url.searchParams.delete('category');
    }

    if (newFilters.tag) {
      url.searchParams.set('tag', newFilters.tag);
    } else {
      // If tag is null, remove it from the URL
      url.searchParams.delete('tag');
    }

    // console.log('New URL:', url.toString(), newFilters);
    // Update the browser URL without reloading the page
    window.history.pushState({}, '', url.toString());
  }
);

// To read URL params on initial load and sync them with the state
export const initializeFiltersFromUrlAtom = atom(null, (_get, set) => {
  const queryParams = new URLSearchParams(window.location.search);

  const filters = {
    category: queryParams.get('category') || null,
    tag: queryParams.get('tag') || null,
  };

  set(searchFiltersAtom, filters);
});
